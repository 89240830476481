$path-to-img: "../../landkit/src/img";
$path-to-fonts: "../../landkit/src/fonts";

$font-size-sm: 0.875rem;
$display-letter-spacing: -0.03em !default;
$navbar-nav-link-font-weight: 400 !default;
$navbar-light-color: #000 !default;
$box-shadow: 0 0.5rem 1.5rem fade-out(#abbcd5, 0.9);

@import "~quill/dist/quill.core.css";
@import "~swiper/css/bundle";
@import '~flickity/dist/flickity.min.css';
@import '~flickity-fade/flickity-fade.css';

@import "../../landkit/src/scss/theme";
@import "mixins";

/* For Alpine */
[x-cloak] { display: none !important; }

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

/* Cookies banner (Iubenda.com) */
#iubenda-cs-banner {
  font-family: HKGroteskPro, serif !important;
}

#iubenda-cs-banner #iubenda-cs-title {
  font-size: $font-size-lg !important;
  line-height: $line-height-base !important;
  font-weight: 400 !important;
  margin-bottom: 3rem !important;
}

#iubenda-cs-banner .iubenda-banner-content {
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
}

.btn {
  transition: background-color 100ms ease-out;
}

.btn-primary {
  &:disabled,
  &.disabled {
    background-color: $gray-300;
    color: $gray-700;
  }
}

.btn.lift {
  transition-property: box-shadow, transform, background-color;
  transition-duration: 250ms;
  transition-timing-function: ease;
}

h5 {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: 600;
}

.ticket-list-item {
  padding: 16px 16px 16px 12px;
  display: block;
  border-bottom: solid 1px #f1f4f8;
  border-left: solid 5px #fff;

  &:hover,
  &:focus {
    text-decoration: none;
    border-left: solid 5px #335eea;
  }

  &.active {
    border-left: solid 5px #335eea;
  }
}

.ticket-message {
  padding: 16px;
  font-size: 14px;

  &.internal-note {
    background-color: rgba(250, 215, 118, 0.1);
  }

  .message-from-name {
    color: #000;
    font-weight: 600;
    font-size: 14px;
    margin-left: 1px;
    margin-right: 3px;
  }

  .message-from-email {
    font-size: 14px;
  }

  .message-body {
    font-size: 16px;
  }
}

.widgets {
  background-color: #fff;
  font-size: 14px;
  padding: 0;
}

.shopify-order-info-basic {
  cursor: pointer;
  padding: 16px;
}

.static-info-accordion-loader-container {
  @extend .p-4;
  @extend .bg-gray-200;
}

.static-info-accordion-item {
  @extend .p-4;
  @extend .bg-gray-200;

  &:not(:last-child) {
    @extend .border-bottom;
    @extend .border-gray-300;
  }
}

.emphasized-col {
  border-right: 2px solid #42ba96 !important;
  border-left: 2px solid #42ba96 !important;

  &.last-row {
    border-bottom: 2px solid #42ba96 !important;
  }
}

th.emphasized-col {
  border-top: 2px solid #42ba96 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.blob {
  background: #42ba96;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 1);
  transform: scale(1);
  animation: pulse 2s infinite;

  &.danger {
    background: #df4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 1);
    animation: pulse-danger 2s infinite;
  }

  &.warning {
    background: #fad776;
    box-shadow: 0 0 0 0 rgba(250, 215, 118, 1);
    animation: pulse-warning 2s infinite;
  }
}

@keyframes pulse {
  @include pulse-border(#42ba96);
}

@keyframes pulse-danger {
  @include pulse-border(#df4759);
}

@keyframes pulse-warning {
  @include pulse-border(#fad776);
}

.stats-icon-circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 1);
  transform: scale(1);
  display: inline-block;
}

.stats-heading {
  h1 {
    line-height: 1.2;
  }

  h3 {
    line-height: 1.3;
  }
}

.table-wdp-benefits {
  td,
  th {
    @include media-breakpoint-down(md) {
      padding: 16px 4px;
    }
  }

  .comparison-text {
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.ticket-status-badge {
  font-size: $font-size-xs;
  text-transform: capitalize;

  &.ticket-status-open,
  &.ticket-status-new {
    @include badge-variant-soft($success, $badge-soft-bg-opacity);
  }

  &.ticket-status-deleted {
    @include badge-variant-soft($danger, $badge-soft-bg-opacity);
  }

  &.ticket-status-pending,
  &.ticket-status-hold {
    @include badge-variant-soft($warning, $badge-soft-bg-opacity);
  }

  &.ticket-status-closed,
  &.ticket-status-solved {
    @include badge-variant-soft($secondary, $badge-soft-bg-opacity);
  }
}

.gdoc ol {
  counter-reset: section;
  list-style-type: none;
  padding-left: 0;

  li::before {
    counter-increment: section;
    content: counters(section, ".") " ";
  }

  li {
    font-size: $h2-font-size;
    margin-top: 1rem;

    li {
      font-weight: normal;
      font-size: $font-size-base;
      margin-top: 0;
    }

    ol {
      padding-left: 1rem;
    }
  }
}

.testimonial-logo-secondary {
  position: absolute;
  width: 110px;
  height: 110px;
  line-height: 110px;
  margin-top: 220px;
  margin-left: -33px;

  &.position-2 {
    margin-top: 40px;
    margin-left: -60px;
    line-height: 70px;
    width: 70px;
    height: 70px;
  }
}

.client-testimonial-block {
  margin-bottom: 64px;

  .logo {
    width: 180px;
    height: 180px;
    line-height: 180px;
  }

  .circle {
    width: 24px;
    height: 24px;
    position: absolute;
    margin-top: 80px;
    margin-left: -40px;

    &.circle-right {
      margin-top: 120px;
      margin-left: 200px;
    }
  }

  .testimonial {
    width: 260px;
    font-size: 15px;
    line-height: 18px;
    margin-top: -25px;

    img {
      position: absolute;
      margin-top: -25px;
      margin-left: -45px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .client-testimonial-block {
    display: inline-block;
    position: absolute;
    width: 180px;
    height: 180px;

    .testimonial {
      position: absolute;
      display: inline-block;
    }

    &:first-child {
      margin-left: -210px;
      top: 190px;

      .testimonial {
        margin-top: -230px !important;
        margin-left: -200px !important;
      }
    }

    &:last-child {
      margin-left: 20px;
      top: 140px;

      .testimonial {
        margin-top: -225px !important;
        margin-left: 130px !important;
      }
    }
  }
}

.slide-list a.active {
  text-decoration: underline !important;
}

#progress-bar {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  background-color: #335eea;
  height: 4px;
  z-index: 100;
  width: 0;

  &.active {
    width: 100%;
  }
}

.why-wdp-box {
  width: 230px;
  height: 256px;
  border-width: 2px !important;

  &:hover {
    border-color: $primary !important;
  }
}

.integrations {
  .recharge-logo {
    margin-top: 4px;
  }

  .zendesk-logo {
    margin-top: 2px;
  }

  .gorgias-logo {
    margin-top: 6px;
  }
}

.founders-separator {
  background: #335eea;
  position: absolute;
  height: 100px;
  width: 100%;
  margin-top: -100px;
  z-index: -5;
}

.bubble {
  position: absolute;
  display: inline-block;
  background: #e3e3e5;
  color: #434343;
  font-size: 15px;
  border-radius: 30px;
  padding: 6px 13px;
  box-shadow: 0 24px 64px rgba(22, 27, 45, 0.1);
  transition: all 400ms cubic-bezier(0.61, 0.02, 0.44, 1.01);

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 23px 14px 0;
    border-color: transparent #e3e3e5 transparent transparent;
    bottom: -14px;
    right: 10%;
    margin-left: -11px;
  }
}

.nav {
  &.nav-borderless {
    border: 0 !important;
  }

  .nav-link {
    cursor: pointer;
  }

  &.nav-sm {
    .nav-link {
      font-size: $font-size-sm !important;
      padding: 0.3rem 0.5rem !important;
    }
  }

  &.nav-settings {
    .nav-link {
      color: $gray-800;
      font-weight: 400;
      padding: 0.3rem 0 !important;

      &:hover,
      &.active {
        color: $primary;
        transition: color 200ms ease-in-out;
      }
    }

    .nav-item {
      >.nav {
        padding-left: 1.6rem;

        .nav-link {
          color: $gray-700;

          &:hover,
          &.active {
            color: $primary;
            transition: color 200ms ease-in-out;
          }
        }
      }
    }
  }
}

.ql-toolbar,
.ql-editor {
  transition: background-color 100ms ease-out;
}

.nav-tabs + .ql-toolbar {
  border-top-left-radius: 0 !important;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.opacity-50 {
  opacity: 0.5;
}

.settings-fieldset {
  .form-group {
    margin-bottom: 0;
  }

  .settings-input-group {
    @extend .pb-5;

    &:not(:first-child) {
      @extend .pt-5;
    }

    &:not(:last-child) {
      @extend .border-bottom;
    }
  }
}

#support-platform-card {
  .btn-outline-primary {
    border-color: #d9e2ef;
    width: 170px;

    &:hover {
      border-color: #c6d3e6;
      background-color: transparent;
    }

    &:active {
      border-color: #c6d3e6;
      background-color: transparent;
    }
  }

  .btn-check:checked + .btn-outline-primary {
    border-color: #335eea;
    background-color: transparent;
  }
}

.text-strike-through {
  text-decoration: line-through !important;
}

.main-navbar-scrolled {
  @extend .border-bottom;
  @extend .shadow;

  border-color: $gray-200 !important;
}

.snippet-action {
  white-space: normal;
}

.btn-check + .btn-other-integrations-platform {
  @extend .btn-primary-soft;
  @extend .py-5;
  @extend .px-7;

  border: 1px solid transparent !important;

  &:hover {
    border-color: $primary !important;
  }
}

.btn-check:checked + .btn-other-integrations-platform {
  border-color: $primary !important;
}
